import { Component } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrl: './admin-layout.component.scss'
})
export class AdminLayoutComponent {

  opened: boolean = true;
  showToolbar: boolean = true;

  constructor(private router: Router) {
  }

  ngOnInit() {

    // Hide toolbar on levels page
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // This regex pattern will match any URL that contains /levels/ followed by any alphanumeric character or hyphen
      const pattern = /\/levels\/[a-zA-Z0-9-]+$/;
      if (pattern.test(event.url)) {
        this.showToolbar = false;
      } else {
        this.showToolbar = true;

      }
    });
  }

  toggleSidenav(value: any) {
    this.opened = value;
  }

}

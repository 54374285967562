<mat-toolbar class="mat-toolbar-white">
  <div class="toolbar">
    <a href="/home" class="flex items-center gap-3">


      <div class="p-2 mat-primary-background flex justify-center items-center rounded-lg">
        <mat-icon svgIcon="circle-grid-interface-bold" class="text-white" style="width: 18px;
  height: 18px;"></mat-icon>
      </div>

      <h1 class="text-3xl font-bold tracking-tight  ">Fyyyre</h1>

    </a>

    <div class="flex flex-wrap flex-row items-center gap-4">
      <mat-divider [vertical]="true" style="height: 20px"></mat-divider>
      <mat-slide-toggle [(ngModel)]="sidenavState" (change)="toggleSideNav(sidenavState)" color="primary"><b
          class="">Sidenav {{ sidenavState ? "Open" : "Close" }}</b></mat-slide-toggle>

      @if(getEnvType()){
      <span class="bg-green-900 text-green-100 text-sm font-medium me-2 px-2.5 py-0.5 rounded">Environnement
        de production</span>

      }@else{
      <span class="bg-blue-500 text-blue-50 text-sm font-medium me-2 px-2.5 py-0.5 rounded ">Environnement de
        développement
      </span>
      }

      <mat-divider [vertical]="true" style="height: 20px"></mat-divider>
      <mat-icon [routerLink]="['/settings']" svgIcon="setting-light"></mat-icon>

      <mat-divider [vertical]="true" style="height: 20px"></mat-divider>

      @if ((user$ | async)?.avatar) {
      <img class="user-circle" src="{{ (user$ | async)?.avatar }}" alt="image" crossorigin="anonymous"
        [routerLink]="'/home/profile'" style="margin-left: -5px" />
      } @else {
      <img class="user-circle p-1" src="assets/svg/iconly/user-bold.svg" alt="image" [routerLink]="'/home/profile'"
        style="margin-left: -5px" />
      }
    </div>
  </div>
</mat-toolbar>

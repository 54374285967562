<section class="bg-slate-300 flex flex-row items-center" fxLayout="row">
  <!-- Auth Image -->
  <!-- <div class="flex justify-center basis-0 lg:basis-1/2"> -->
  <!--   <img src="/assets/logo/geeki-logo.png"/> -->
  <!-- <img src="/assets/logo/tigre_accueil_sansfond.png" />
  </div> -->
  <!-- / Auth Image -->
  <!-- Auth Content -->
  <div class="bg-color w-screen mx-auto">
    <router-outlet></router-outlet>
  </div>
  <!-- / Auth Content -->
</section>

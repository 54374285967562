import { Component } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthService } from '@core/services/auth.service';
import { ThemeService } from '@core/services/theme.service';

export interface NavPart {
  title: string
  navItems: Array<NavItem>
}

export interface NavItem {
  displayName: string;
  disabled?: boolean;
  iconName?: string;
  route?: string;
  fragment?: string;
  childrenExpanded?: boolean;
  cssClass?: String[];
  children?: NavItem[];
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
})
export class SidenavComponent {



  navItemsMenu: NavItem[] = [
    {
      displayName: 'Home',
      iconName: 'home-bold',
      route: 'admin',
    },
    {
      displayName: 'Serious Game',
      iconName: 'folder-bold',
      route: 'admin/serious-game',
      childrenExpanded: false,
      children: [
        {
          displayName: 'Chapitres',
          //  iconName: 'notebook-document-bulk',
          route: 'serious-game/chapters',
          cssClass: [],
        },
        {
          displayName: 'Histoires',
          //  iconName: 'edit-square-bulk',
          route: 'serious-game/history',
          cssClass: [],
        },
        {
          displayName: 'Succès',
          //  iconName: 'check-badge-bulk',
          route: 'serious-game/success',
          cssClass: [],
        },
        {
          displayName: 'Classement',
          //  iconName: 'rank-star-bulk',
          route: 'serious-game/ranking',
          cssClass: [],
        },
      ]
    },
    {
      displayName: 'Utilisateurs',
      iconName: 'user-multiple-light',
      route: 'admin/users',
    }
  ];

  navConfig: Array<NavPart> = [
    {
      title: "MENU",
      navItems: this.navItemsMenu
    }
  ]

  constructor(public themeService: ThemeService, private authService: AuthService) { }

  // Change current Theme ( Light/Dark )
  toggle({ checked }: MatSlideToggleChange): void {
    this.themeService.setMode(checked);
  }

  // Disconnect
  signOut() {
    this.authService.signOut();
  }
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GameService } from '@app/data/services/game.service';
import { CreateOrEditDialogComponent } from '@app/shared/components/create-or-edit-dialog/create-or-edit-dialog.component';
import { LoadingDialogComponent } from '@app/shared/components/loading-dialog/loading-dialog.component';
import { AuthService } from '@core/services/auth.service';
import { OrganizationService } from '@data/services/organization.service';
import { environment as env } from '@env';
import { SnackbarService } from '@shared/services/snackbar.service';
import { map } from 'rxjs';
import gameModel from '../../shared/other/import_model/game.json';

const envType = env.production;

@Component({
  selector: 'app-toolbar-admin',
  templateUrl: './toolbar-admin.component.html',
  styleUrl: './toolbar-admin.component.scss',
})
export class ToolbarAdminComponent {
  menuOpenedState = false;

  user$ = this.authService.user;

  user: any;

  isAdmin = false;
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private organizationService: OrganizationService,
    private gameService: GameService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user$.subscribe((user) => {
      this.user = user;
      this.checkIsAdmin();
    });
  }

  checkIsAdmin() {
    if ((this.authService.userValue!.role?.level ?? 99) <= 1) {
      this.isAdmin = true;
    }
  }

  /**
   * Toggle the burger menu
   */
  toggleMenu() {
    this.menuOpenedState = !this.menuOpenedState;
  }

  signOut() {
    this.authService.signOut();
  }

  openCreateOrUpdateInstanceDialog(): void {
    const dialogRef = this.dialog.open(CreateOrEditDialogComponent, {
      width: `${innerWidth > 900 ? innerWidth * 0.8 : 900}px`,
      disableClose: true,
      data: {
        hasImport: true,
        onImportPage: true,
        field: [
          {
            key: 'organisation',
            type: 'autocomplete',
            defaultValue: [],
            props: {
              label: 'Organisation',
              required: true,
              multiple: false,
              valueProp: 'id',
              labelProp: (option: any) => option.name,
              filter: (term: any) => this.filterOrganisation(term),
            },
          },
          {
            key: 'name',
            type: 'input',
            defaultValue: [],
            props: {
              label: 'Nom du serious game',
              required: true,
            },
          },
        ],
        importField: [
          {
            key: 'organisation',
            type: 'autocomplete',
            defaultValue: [],
            props: {
              label: 'Organisation',
              required: true,
              multiple: false,
              valueProp: 'id',
              labelProp: (option: any) => option.name,
              filter: (term: any) => this.filterOrganisation(term),
            },
          },
          {
            key: 'file',
            type: 'dragNdrop',
            className: 'md:flex-grow',
            props: {
              accept: ['json'],
              multiple: true,
              label: 'Importer un fichier au format ( JSON )',
              required: true,
              option: [],
            },
          },
        ],

        importInstructions: {
          title: "Instructions pour l'import",
          instruction: 'Format du JSON attendu : ',
          preContent: this.getGameImportFormatInstruction(),
        },
        model: {},
        importModel: {},
        config: {
          header: {
            icon: '',
            label: 'Créer un serious game',
          },
          actions: {
            cancel: 'Annuler',
            submit: 'Valider',
          },
          tabLabels: {
            firstTab: 'Ajout par formulaire',
            secondTab: 'Ajout par import CSV',
          },
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result != true) {
        if (result.file && result.file[0] && result.file[0] instanceof File) {
          const loadingref = this.dialog.open(LoadingDialogComponent, {
            disableClose: true,
            width: '900px',
            data: {
              title: 'Création du serious game',
              content:
                'La création peut prendre un peu de temps. Veuillez patienter',
            },
          });
          const formData = new FormData();
          formData.append('file', result.file[0]);
          this.gameService
            .createGameFromJson(result.organisation, formData)
            .subscribe({
              next: (response) => {
                this.snackbarService.openSnackBar(
                  'Serious game créé',
                  'Fermer'
                );
                loadingref.close();
                this.router.navigate(['/admin', 'serious-game', response.id]);
              },
              error: (error) => {
                this.snackbarService.openSnackBar(
                  'Erreur lors de la création du serious game',
                  'Fermer'
                );
                loadingref.close();
              },
            });
        } else {
          this.gameService
            .createGame({
              name: result.name,
              organisation: result.organisation,
            })
            .subscribe({
              next: (response) => {
                this.snackbarService.openSnackBar(
                  'Serious game créé',
                  'Fermer'
                );
                this.router.navigate(['/admin', 'serious-game', response.id]);
              },
              error: (error) => {
                this.snackbarService.openSnackBar(
                  'Erreur lors de la création du serious game',
                  'Fermer'
                );
              },
            });
        }
      }
    });
  }

  filterOrganisation(searchTerm: string) {
    return this.organizationService.getOrganizations(searchTerm).pipe(
      map((data) => {
        return data['data'];
      })
    );
  }

  getGameImportFormatInstruction() {
    return gameModel;
  }
}

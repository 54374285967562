<div class="flex flex-col justify-between h-full py-3">
  <!-- Nav Links - Version Dynamic -->
  <mat-nav-list class="modern">
    @for (navElement of navConfig; track navElement.title) {

    <span class="nav-link-header mx-3">{{navElement.title}}</span>
    <app-menu-list-item *ngFor="let item of navElement.navItems" [item]="item"></app-menu-list-item>
    }


  </mat-nav-list>

</div>

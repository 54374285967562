import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { environment as env } from '@env';

const envType = env.production;
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent {
  @Input() sidenavState!: boolean;
  @Output() opened = new EventEmitter<boolean>();

  user$ = this.authService.user;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    console.log('User', )
  }

  signOut() {
    this.authService.signOut();
  }

  toggleSideNav(value: boolean) {
    this.opened.emit(value);
  }

  getEnvType() {
    return envType;
  }
}

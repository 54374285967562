import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from "@core/services/auth.service";
import { environment as env } from "@env";
import { NavItem, NavPart } from "@app/layouts/sidenav/sidenav.component";

const envType = env.production;

@Component({
  selector: 'app-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrl: './toolbar-user.component.scss'
})
export class ToolbarUserComponent {

  menuOpenedState = false;

  user$ = this.authService.user;

  constructor(private authService: AuthService) { }

  /**
   * Toggle the burger menu
   */
  toggleMenu() {
    this.menuOpenedState = !this.menuOpenedState;
  }

  signOut() {
    this.authService.signOut();
  }

}

import { Component } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { ThemeService } from '@core/services/theme.service';
import { filter } from "rxjs";

@Component({
  selector: 'app-user-nav-layout',
  templateUrl: './user-nav-layout.component.html',
  styleUrl: './user-nav-layout.component.scss'
})
export class UserNavLayoutComponent {

  opened: boolean = true;
  showToolbar: boolean = true;
  defaultTheme !: boolean;

  constructor(private router: Router, public themeService: ThemeService) { }

  ngOnInit() {

    // Hide toolbar on levels page
    this.router.events.subscribe((event: any) => {

      if (event instanceof NavigationEnd) {

        // This regex pattern will match any URL that contains /levels/ followed by any alphanumeric character or hyphen
        const pattern = /\/levels\/[a-zA-Z0-9-]+$/;
        if (pattern.test(event.url)) {
          this.showToolbar = false;
        } else {
          this.showToolbar = true;
        }

        // Check for reset Default Theme ( Light / Dark )
        if (!event.url.includes("/serious-game/")) {
          this.themeService.resetThemeToDefault();
        } else {
          this.defaultTheme = this.themeService.mode;
        }

      }

    });
  }

  toggleSidenav(value: any) {
    this.opened = value;
  }
}

<!-- <app-toolbar-admin *ngIf="showToolbar"></app-toolbar-admin> -->
<app-toolbar-admin></app-toolbar-admin>

<mat-sidenav-container [ngClass]="!showToolbar ? 'h-dvh' : ''">
  <!-- <mat-sidenav mode="side" [(opened)]="!opened" #sidenav>
    <app-sidenav></app-sidenav>
  </mat-sidenav> -->

  <mat-sidenav-content>
    <div id="content-start"></div>

    <div class="router-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

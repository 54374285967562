import { Component } from '@angular/core';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './core/services/auth.service';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'fyyyre-angular';

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private authService: AuthService,
    public themeService: ThemeService
  ) {
    // Define All Custom SVG Icon as Angular Material Icons
    const resolver: IconResolver = (name) =>
      sanitizer.bypassSecurityTrustResourceUrl(
        `/assets/svg/iconly/${name}.svg`
      );
    iconRegistry.addSvgIconResolver(resolver);

  }

  ngOnInit(): void {
    this.authService.loadAppSession();
  }
}

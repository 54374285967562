<mat-toolbar
  class="toolbar-user-mobile mat-toolbar-white flex flex-row justify-between"
>
  <!-- Logo -->
  <a href="/home" class="flex flex-row items-center">
    <img
      src="assets/logo/fyyyre-tmp-logo-short.png"
      alt=""
      width="50"
      height="50"
      class="p-2 flex justify-center items-center rounded-lg"
    />

    <h1 class="text-3xl font-bold tracking-tight">Fyyyre</h1>
  </a>

  <!-- Logo -->

  <!-- Menu Burger for MobileView -->
  <div class="flex items-center justify-end lg:hidden">
    <div class="flex items-center">
      <button
        (click)="toggleMenu()"
        [matMenuTriggerFor]="menu"
        [ngClass]="{ active: menuOpenedState, 'not-active': !menuOpenedState }"
        class="btn not-active sm:scale-50 mobile-menu"
      >
        <span class="dark:bg-white"></span>
        <span class="dark:bg-white"></span>
        <span class="dark:bg-white"></span>
      </button>
    </div>

    <mat-menu
      yPosition="below"
      [overlapTrigger]="false"
      #menu="matMenu"
      (closed)="toggleMenu()"
      class="mat-elevation-z0 nav-mobile translate-y-4"
    >
      <mat-divider></mat-divider>
      <div class="toolbar-menu-burger">
        <button
          [routerLink]="
            (user$ | async)!.role!.level <= 1 ? '/admin/home' : '/home'
          "
          mat-menu-item
        >
          <a
            [routerLinkActive]="'active-list-item-v3'"
            [routerLinkActiveOptions]="{ exact: true }"
            >Accueil</a
          >
        </button>

        <button
          [routerLink]="
            (user$ | async)!.role!.level <= 1
              ? '/admin/adventures'
              : '/home/adventures'
          "
          mat-menu-item
        >
          <a
            [routerLinkActive]="'active-list-item-v3'"
            [routerLinkActiveOptions]="{ exact: true }"
            >Mes aventures</a
          >
        </button>

        <button
          [routerLink]="
            (user$ | async)?.currentGame && (user$ | async)?.currentGameInstance
              ? [
                  '/serious-game',
                  ((user$ | async)?.currentGame)!.id,
                  'instances',
                  ((user$ | async)?.currentGameInstance)!.id
                ]
              : null
          "
          mat-menu-item
        >
          <a
            [ngClass]="
              !(user$ | async)?.currentGame
                ? 'pointer-events-none'
                : 'cursor-pointer'
            "
            [routerLinkActive]="'active-list-item-v3'"
            [className]="(user$ | async)?.currentGame ? null : 'disabled'"
          >
            <div class="flex flex-row items-center gap-2.5">
              @if ((user$ | async)?.currentGame?.mascot_url) {
              <img
                class="w-5"
                [src]="(user$ | async)?.currentGame?.mascot_url"
              />
              }

              {{ (user$ | async)?.currentGame?.name || "Sélectionner un jeu" }}
            </div>
          </a>
        </button>

        <button
          class=""
          [routerLink]="
            (user$ | async)?.currentGame && (user$ | async)?.currentGameInstance
              ? [
                  '/serious-game',
                  ((user$ | async)?.currentGame)!.id,
                  'instances',
                  ((user$ | async)?.currentGameInstance)!.id,
                  'story'
                ]
              : null
          "
          mat-menu-item
        >
          <a
            [ngClass]="
              !(user$ | async)?.currentGame
                ? 'pointer-events-none'
                : 'cursor-pointer'
            "
            [routerLinkActive]="'active-list-item-v3'"
            [className]="(user$ | async)?.currentGame ? null : 'disabled'"
            >Histoire</a
          >
        </button>

        <button
          class=""
          [routerLink]="
            (user$ | async)?.currentGame && (user$ | async)?.currentGameInstance
              ? [
                  '/serious-game',
                  ((user$ | async)?.currentGame)!.id,
                  'instances',
                  ((user$ | async)?.currentGameInstance)!.id,
                  'progress'
                ]
              : null
          "
          mat-menu-item
        >
          <a
            [ngClass]="
              !(user$ | async)?.currentGame
                ? 'pointer-events-none'
                : 'cursor-pointer'
            "
            [routerLinkActive]="'active-list-item-v3'"
            [className]="(user$ | async)?.currentGame ? null : 'disabled'"
            >Progresssion</a
          >
        </button>

        <button
          class=""
          [routerLink]="
            (user$ | async)?.currentGame && (user$ | async)?.currentGameInstance
              ? [
                  '/serious-game',
                  ((user$ | async)?.currentGame)!.id,
                  'instances',
                  ((user$ | async)?.currentGameInstance)!.id,
                  'ranking'
                ]
              : null
          "
          mat-menu-item
        >
          <a
            [ngClass]="
              !(user$ | async)?.currentGame
                ? 'pointer-events-none'
                : 'cursor-pointer'
            "
            [routerLinkActive]="'active-list-item-v3'"
            [className]="(user$ | async)?.currentGame ? null : 'disabled'"
            >Classement</a
          >
        </button>

        <mat-divider></mat-divider>

        <button mat-menu-item>
          <mat-icon svgIcon="setting-light"></mat-icon>
          <a
            [routerLinkActive]="'active-list-item-v3'"
            [routerLink]="
              (user$ | async)!.role!.level <= 1
                ? '/admin/profile'
                : '/home/profile'
            "
            [queryParams]="{ tab: 2 }"
            [routerLinkActive]="'active-list-item-v3'"
            >Settings</a
          >
        </button>

        <button
          [routerLink]="
            (user$ | async)!.role!.level <= 1
              ? '/admin/profile'
              : '/home/profile'
          "
          mat-menu-item
        >
          <div class="flex flex-row items-center gap-5">
            @if ((user$ | async)?.avatar) {
            <img
              class="user-circle-mobile"
              src="{{ (user$ | async)?.avatar }}"
              alt="image"
              crossorigin="anonymous"
              [routerLink]="
                (user$ | async)!.role!.level <= 1
                  ? '/admin/profile'
                  : '/home/profile'
              "
            />
            } @else {
            <img
              class="user-circle-mobile p-1"
              src="assets/svg/iconly/user-bold.svg"
              alt="image"
              [routerLink]="
                (user$ | async)!.role!.level <= 1
                  ? '/admin/profile'
                  : '/home/profile'
              "
            />
            }
            <a [routerLinkActive]="'active-list-item-v3'">Profil</a>
          </div>
        </button>

        <button (click)="signOut()" mat-menu-item>
          <mat-icon class="-translate-x-1" svgIcon="sign-out-light"></mat-icon>
          <a> Déconnexion </a>
        </button>
      </div>
    </mat-menu>
  </div>

  <!-- Menu Burger for MobileView -->

  <!-- Menu for DesktopView -->
  <div class="hidden lg:flex flex-row items-center gap-5">
    <a
      [routerLink]="(user$ | async)!.role!.level <= 1 ? '/admin/home' : '/home'"
      [routerLinkActive]="'active-list-item-v3'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Accueil</a
    >

    <a
      [routerLink]="
        (user$ | async)!.role!.level <= 1
          ? '/admin/adventures'
          : '/home/adventures'
      "
      [routerLinkActive]="'active-list-item-v3'"
      [routerLinkActiveOptions]="{ exact: true }"
      >Mes aventures</a
    >

    <mat-divider [vertical]="true" class="h-5"></mat-divider>

    @if((user$ | async)?.currentGame){
    <a
      [ngClass]="
        !(user$ | async)?.currentGame ? 'pointer-events-none' : 'cursor-pointer'
      "
      [routerLinkActive]="'active-list-item-v3'"
      [routerLink]="
        (user$ | async)?.currentGame && (user$ | async)?.currentGameInstance
          ? [
              '/serious-game/',
              ((user$ | async)?.currentGame)!.id,
              'instances',
              ((user$ | async)?.currentGameInstance)!.id
            ]
          : null
      "
      [className]="(user$ | async)?.currentGame ? null : 'disabled'"
    >
      <div class="flex flex-row items-center gap-2.5 max-w-80">
        @if ((user$ | async)?.currentGame?.mascot_url) {
        <img class="w-5" [src]="(user$ | async)?.currentGame?.mascot_url" />
        }
        <div class="w-full text-ellipsis overflow-hidden whitespace-nowrap">
          {{ (user$ | async)?.currentGame?.name || "Sélectionner un jeu" }}
        </div>
      </div>
    </a>
    <a
      [ngClass]="
        !(user$ | async)?.currentGame ? 'pointer-events-none' : 'cursor-pointer'
      "
      [routerLinkActive]="'active-list-item-v3'"
      [routerLink]="
        (user$ | async)?.currentGame && (user$ | async)?.currentGameInstance
          ? [
              '/serious-game',
              ((user$ | async)?.currentGame)!.id,
              'instances',
              ((user$ | async)?.currentGameInstance)!.id,
              'story'
            ]
          : null
      "
      [className]="(user$ | async)?.currentGame ? null : 'disabled'"
      >Histoire</a
    >
    <a
      [ngClass]="
        !(user$ | async)?.currentGame ? 'pointer-events-none' : 'cursor-pointer'
      "
      [routerLinkActive]="'active-list-item-v3'"
      [routerLink]="
        (user$ | async)?.currentGame && (user$ | async)?.currentGameInstance
          ? [
              '/serious-game',
              ((user$ | async)?.currentGame)!.id,
              'instances',
              ((user$ | async)?.currentGameInstance)!.id,
              'progress'
            ]
          : null
      "
      [className]="(user$ | async)?.currentGame ? null : 'disabled'"
      >Progresssion</a
    >
    <a
      [ngClass]="
        !(user$ | async)?.currentGame
          ? 'cursor-not-allowed pointer-events-none'
          : 'cursor-pointer'
      "
      [routerLinkActive]="'active-list-item-v3'"
      [routerLink]="
        (user$ | async)?.currentGame && (user$ | async)?.currentGameInstance
          ? [
              '/serious-game',
              ((user$ | async)?.currentGame)!.id,
              'instances',
              ((user$ | async)?.currentGameInstance)!.id,
              'ranking'
            ]
          : null
      "
      [className]="(user$ | async)?.currentGame ? null : 'disabled'"
      >Classement</a
    >

    <mat-divider [vertical]="true" class="h-5"></mat-divider>
    }
    <a
      [routerLinkActive]="'active-list-item-v3'"
      [routerLink]="
        (user$ | async)!.role!.level <= 1 ? '/admin/profile' : '/home/profile'
      "
      [queryParams]="{ tab: 2 }"
    >
      <mat-icon class="toolbar-icon" svgIcon="setting-light"></mat-icon>
    </a>

    <mat-divider [vertical]="true" class="h-5"></mat-divider>

    @if ((user$ | async)?.avatar) {
    <img
      class="user-circle"
      src="{{ (user$ | async)?.avatar }}"
      alt="image"
      crossorigin="anonymous"
      [routerLink]="
        (user$ | async)!.role!.level <= 1 ? '/admin/profile' : '/home/profile'
      "
      style="margin-left: -5px"
    />
    } @else {
    <img
      class="user-circle p-1 bg-gray-400"
      src="assets/svg/iconly/user-bold.svg"
      alt="image"
      [routerLink]="
        (user$ | async)!.role!.level <= 1 ? '/admin/profile' : '/home/profile'
      "
      style="margin-left: -5px"
    />
    }
  </div>
  <!-- Menu for DesktopView -->
</mat-toolbar>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from "@core/services/auth.service";
import { environment as env } from "@env";
import { NavItem, NavPart } from "@app/layouts/sidenav/sidenav.component";

const envType = env.production;

@Component({
  selector: 'app-toolbar-admin',
  templateUrl: './toolbar-admin.component.html',
  styleUrl: './toolbar-admin.component.scss'
})
export class ToolbarAdminComponent {

  menuOpenedState = false;

  user$ = this.authService.user;

  user: any;
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.user$.subscribe((user) => {
      this.user = user;
    });

  }

  /**
   * Toggle the burger menu
   */
  toggleMenu() {
    this.menuOpenedState = !this.menuOpenedState;
  }

  signOut() {
    this.authService.signOut();
  }

}
